import { PanelResolveType } from '@wix/platform-editor-sdk';
import { blogAppDefId } from '../external/common/constants/apps';
import { TPA_PAGE_ID_POST } from '../external/common/constants/tpa-pages';
import { EditorAppContext } from '../types/editor-app-context.type';
import { installNewPostPage } from './install-new-post-page';

const removeOldPostWidget = async (context: EditorAppContext) => {
  const pageRef = await context.sdk.tpa.getPageRefByTPAPageId(
    context.appToken,
    { tpaPageId: TPA_PAGE_ID_POST },
  );

  await context.sdk.document.pages.remove(context.appToken, {
    pageRef,
    shouldShowEditorRemovePanel: false,
  });
};

export const replaceOldPostPage = async (context: EditorAppContext) => {
  await removeOldPostWidget(context);
  await installNewPostPage(context);
};

export const replaceOldPostPageModal = async (context: EditorAppContext) => {
  const confirmed = await context.sdk.editor.openConfirmationPanel(
    context.appToken,
    {
      headerText: 'Do you want to replace Old Post Page with new one?',
      descriptionText: 'This action cannot be undone.',
      mainActionText: 'Yes',
      secondaryActionText: 'No',
      shouldShowIllustration: false,
    },
  );

  const waitALittle = (time = 3000) => {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  };

  if (confirmed === PanelResolveType.MAIN_ACTION) {
    await context.sdk.editor.openProgressBar(context.appToken, {
      title: 'Replacing Old Post Page',
      totalSteps: 3,
      currentStep: 1,
    });

    const api: any = await context.sdk.application.getPublicAPI(
      context.appToken,
      {
        appDefinitionId: blogAppDefId,
      },
    );

    api.replaceOldPostPage();

    await waitALittle();
    await context.sdk.editor.updateProgressBar(context.appToken, {
      stepTitle: 'Crunching styles',
      currentStep: 2,
    });

    await waitALittle();
    await context.sdk.editor.updateProgressBar(context.appToken, {
      stepTitle: 'Finalizing migration',
      currentStep: 3,
    });

    await waitALittle();
    await context.sdk.editor.closeProgressBar(context.appToken, {});
  }
};
